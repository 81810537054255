import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import styled from 'styled-components'
import { breakpoints, colors } from '../theme'
import Link from './Link'
import Shape from './Shape'
import Arrow from '../assets/arrow.svg'

const mailTo = label => {
  const subject = encodeURIComponent(`Organiser un ${label}`)
  const body = encodeURIComponent('')
  const mailtoLink = `mailto:sales@monbanquet.fr?subject=${subject}&body=${body}`
  window.location.href = mailtoLink
}

const Card = ({
  label,
  subLabel,
  info,
  picture,
  linkedPage,
  link,
  chip,
  cta = 'Commander',
  event = false,
  menu = false,
  logo,
  shapeStyle,
  ...props
}) => (
  <Link to={(linkedPage && `/${linkedPage.slug}`) || link}>
    <StyledCard {...props} className={!shapeStyle && 'no-shape'}>
      <div className="picture">
        {!menu && (
          <Img fluid={picture.fluid} alt={picture.title} loading="lazy" />
        )}
        {menu && picture && picture.fluid && (
          <Img fluid={picture.fluid} alt={picture.title} loading="lazy" />
        )}
        {!event && <div className="add-icon"></div>}
        {event && <div className="logo-over"></div>}
        {event && logo && (
          <Img
            fluid={logo.fluid}
            alt={logo.title}
            className="logo"
            loading="lazy"
          />
        )}
      </div>
      <div
        className="content"
        onClick={cta === 'Découvrir' ? () => mailTo(label) : null}
      >
        {chip && (
          <div
            className="chip"
            dangerouslySetInnerHTML={{ __html: chip }}
          ></div>
        )}
        {shapeStyle && <Shape shapeStyle={shapeStyle} />}
        <figcaption>{label}</figcaption>
        {subLabel && <p className="sublabel">{subLabel}</p>}
        {info && <p className="info">{info}</p>}
        <div className="cta">
          <span className="command">{cta}</span>
          <span className="arrow">
            <img src={Arrow} alt="arrow" />
          </span>
        </div>
      </div>
    </StyledCard>
  </Link>
)

const StyledCard = styled.figure`
  outline: none;
  vertical-align: middle;
  height: 100%;
  display: flex;
  flex-direction: column;

  .picture {
    position: relative;
    height: 300px;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100%;

    .gatsby-image-wrapper {
      position: absolute !important;
      height: 100% !important;
      width: 100% !important;
      transition: all 250ms ease-in-out;
    }

    .add-icon {
      z-index: 0;
      position: relative;
      width: 89px;
      height: 89px;
      display: none;
      transition: display 150ms ease-in-out;

      &:before,
      &:after {
        position: absolute;
        left: 50%;
        content: ' ';
        height: 89px;
        width: 2px;
        background-color: ${colors.brand.normal};
      }

      &:after {
        transform: rotate(90deg);
      }
    }

    .logo-over {
      position: absolute;
      bottom: 0;
      background-color: white;
      height: 0;
      width: 100%;
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      transition: all 250ms ease-in-out;
    }

    .logo {
      opacity: 0;
      z-index: 0;
      transition: none;

      &.gatsby-image-wrapper {
        position: relative !important;
        height: 100px !important;
        width: 150px !important;
        overflow: unset !important;

        img {
          width: unset !important;
          height: unset !important;
        }
      }
    }
  }

  &:hover {
    .picture {
      .gatsby-image-wrapper {
        opacity: 0.3;
      }

      .add-icon {
        display: block;
      }

      .logo-over {
        height: 100%;
      }

      .logo {
        opacity: 1;
        transition: all 500ms ease-in;
      }
    }

    .command {
      color: ${colors.brand.normal};
    }
  }

  .content {
    padding: 30px;
    background-color: ${colors.brand.inverted};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    max-height: 225px;
    flex: 1;

    .chip {
      height: 76px;
      width: 76px;
      border-radius: 50%;
      background-color: ${colors.brand.normal};
      position: absolute;
      right: 38px;
      top: -38px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      color: ${colors.brand.inverted};
      font-family: Centra Bold;
    }

    figcaption {
      text-align: center;
      width: 100%;
      font-family: Bely;
      font-size: 23px;
      font-weight: 500;
      color: ${colors.text.black};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      margin-top: 5px;
      font-size: 14px;
      color: ${colors.text.black};
    }

    .info {
      font-size: 14px;
      color: ${colors.brand.normal};
      margin-top: 5px;
      height: 30px;
      margin-bottom: -32px;
    }

    .cta {
      margin-top: auto;
      margin-left: auto;
      font-size: 12px;
      display: flex;
      align-items: center;

      .command {
        margin-right: 14px;
        transition: color 150ms ease-in-out;
      }
      .arrow {
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
          transform: rotate(180deg);
          max-width: 20px;
        }
      }
    }
  }

  &.no-shape {
    .content {
      align-items: start;
      padding: 18px;
      padding-left: 45px;
      flex: 1;

      figcaption {
        text-align: left;
        width: 100%;
        font-size: 38px;
        margin-top: 34px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        margin-top: 6px;
      }
    }
  }

  @media (max-width: ${breakpoints.desktop}px) {
    .picture {
      height: 240px;
      width: 100%;
    }
    &.no-shape {
      .content {
        padding: 15px 23px;

        figcaption {
          font-size: 24px;
        }

        p {
          margin-top: 3px;
        }
      }
    }
    .content {
      figcaption {
        font-size: 20px;
      }
      p {
        font-size: 13px;
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}px) {
    &.no-shape {
      .content {
        padding-left: 23px;

        figcaption {
          font-size: 24px;
        }

        p {
          margin-top: 3px;
        }
      }
    }
    .content {
      .chip {
        height: 60px;
        width: 60px;
        right: 30px;
        top: -30px;
        font-size: 15px;
      }
      figcaption {
        font-size: 17px;
      }
      p {
        font-size: 12px;
      }
      .info {
        font-size: 12px;
      }
    }
  }
`

export default Card
export { StyledCard }

export const VibeFragment = graphql`
  fragment VibeFragment on ContentfulVibe {
    id
    label
    subLabel
    chip
    picture {
      title
      fluid(quality: 90) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    linkedPage {
      slug
    }
    link
  }
`
