import React, { useContext } from 'react'
import styled from 'styled-components'
import ParallaxShape from '../ParallaxShape'
import CallToAction from '../CallToAction'
import { ModalContext } from '../context/ModalContext'
import { breakpoints, layout, colors } from '../../theme'

const BottomBanner = ({ bottomBanner }) => {
  const { dispatch: dispatchModal } = useContext(ModalContext)

  return (
    <StyledBottomBanner>
      <div className="cta-container">
        <div className="desktop-shapes">
          <ParallaxShape
            x={80}
            y={20}
            shapeStyle={{
              height: '30px',
              width: '30px',
              borderRadius: '50%',
              border: '10px solid #0F7762',
            }}
          />
          <ParallaxShape
            x={20}
            y={5}
            shapeStyle={{
              height: '30px',
              width: '30px',
              borderRadius: '50%',
              border: '10px solid #BC8046',
            }}
          />
          <ParallaxShape
            x={10}
            y={40}
            shapeStyle={{
              height: '24px',
              width: '24px',
              borderRadius: '50%',
              border: '12px solid #0F7762',
            }}
          />
          <ParallaxShape
            x={95}
            y={60}
            shapeStyle={{
              width: '35px',
              height: '19px',
              border: '12px solid #030101',
              borderBottom: 'none',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              transform: 'matrix(-0.87, 0.48, -0.48, -0.87, 0, 0)',
            }}
          />
          <ParallaxShape
            x={90}
            y={0}
            shapeStyle={{
              width: '26px',
              height: '33px',
              top: '620vh',
              right: '-20%',
              backgroundColor: '#BC8046',
              clipPath: 'polygon(75% 0, 100% 0, 25% 100%, 0 100%)',
            }}
          />
        </div>
        <div className="tablet-shapes">
          <ParallaxShape
            x={75}
            y={-20}
            className="tablet-shape"
            shapeStyle={{
              height: '27px',
              width: '27px',
              borderRadius: '50%',
              border: '10px solid #0F7762',
            }}
          />
          <ParallaxShape
            x={5}
            y={30}
            className="tablet-shape"
            shapeStyle={{
              height: '18px',
              width: '18px',
              borderRadius: '50%',
              border: '10px solid #0F7762',
            }}
          />
          <ParallaxShape
            x={90}
            y={40}
            className="tablet-shape"
            shapeStyle={{
              width: '20px',
              height: '20px',
              top: '620vh',
              right: '-20%',
              backgroundColor: '#BC8046',
              clipPath: 'polygon(0 0, 25% 0, 100% 100%, 75% 100%)',
            }}
          />
        </div>
        <CallToAction
          {...bottomBanner}
          ctaClicked={() =>
            dispatchModal({
              type: 'OPEN',
              analytics: { label: 'From_CtaBannerBottom' },
            })
          }
          className="bottom-cta"
        />
      </div>
    </StyledBottomBanner>
  )
}

const StyledBottomBanner = styled.div`
  background-color: ${colors.background.semiLight};

  .cta-container {
    position: relative;
    padding: 80px;

    .tablet-shapes {
      display: none;
    }

    .bottom-cta {
      height: auto;

      & > .wrapper {
        max-width: 800px;
        width: ${layout.width};
        margin: auto;
      }

      .text-wrapper {
        margin: auto;
        text-align: center;
        align-items: center;

        p {
          font-size: 1.1rem;
          line-height: 1.6rem;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.desktop}px) {
    .cta-container {
      position: relative;
      padding: 20px;

      .desktop-shapes {
        display: none;
      }
      .tablet-shapes {
        display: block;
      }

      .bottom-cta {
        height: auto;
        padding: 20px 0;

        & > .wrapper {
          max-width: ${layout.maxWidth}px;
          width: ${layout.width};
          margin: auto;
        }

        .text-wrapper {
          text-align: center;
          align-items: center;

          p {
            font-size: 1.1rem;
            line-height: 1.6rem;
          }
        }
      }
    }
  }
`

export default BottomBanner

export { StyledBottomBanner }
